<template>
  <v-container fluid>
    <v-card>
      <dialog-carta-correcao ref="dialogCartaCorrecao" :dialog="mostrarDialogCartaCorrecao" :nota="notaSelecionada" @fechar="mostrarDialogCartaCorrecao = false" @enviarCarta="enviarCartaCorrecao"></dialog-carta-correcao>

      <v-toolbar flat color="white">
        <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" @click:clear="limparBusca" @keyup.enter="filtrarNotas($event)" max-width="300px" prepend-inner-icon="search" :label="constantes.FILTRAR_NOTA"></v-text-field>
        <v-spacer></v-spacer>
        <v-tooltip bottom v-if="usuario.nivelAcesso !== 'ANRON'">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" fab small color="primary" class="mr-3 elevation-3" :disabled="certificadoVencido" @click="consultarStatusServicoNFe()" :loading="carregandoStatus">
              <v-icon dark v-if="statusSefaz">mdi-access-point</v-icon>
              <v-icon dark v-else color="secondary darken-3">mdi-access-point-off</v-icon>
            </v-btn>
          </template>
          <span>Status Serviço</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" fab small color="primary" class="mr-3 elevation-3" @click="$refs.dialogoInutilizarIntervalo.abrir()">
              <v-icon>mdi-card-bulleted-off-outline</v-icon>
            </v-btn>
          </template>
          <span
            >Inutilizar Faixa <br />
            de Numeração</span
          >
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" fab small color="primary" class="mr-3 elevation-3" @click="$refs.dialogCopiarNota.abrir()">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </template>
          <span>Copiar Nota</span>
        </v-tooltip>
        <v-btn color="primary" @click="novaNota()">{{ constantes.BOTAO_NOVA_NOTA }}</v-btn>
      </v-toolbar>

      <v-divider></v-divider>

      <v-data-table item-key="_id" :options.sync="options" :server-items-length="total" :headers="headers" :items="notas" :loading="carregando">
        <template v-slot:item="props">
          <tr @click="props.item.status === constantes.STATUS_CADASTRADA || props.item.status === constantes.STATUS_PROCESSAMENTO ? '' : props.expand(!props.isExpanded)" style="cursor: pointer">
            <td>{{ props.item.numero | formatarPadZero(6) }}</td>
            <td>{{ props.item.serie | formatarPadZero(3) }}</td>
            <td>
              <v-chip pill outlined
                ><v-avatar left :color="definirCor(props.item.status)">{{ definirLetra(props.item.status) }}</v-avatar
                >{{ props.item.status }}</v-chip
              >
            </td>
            <!-- <td>{{ props.item.status }}</td> -->
            <td>{{ props.item.tipo | codigoParaTipoOperacao }}</td>
            <td>{{ props.item.data_emissao | formatarData }}</td>
            <td class="text-no-wrap">{{ props.item.cliente.cnpjCpf | formatarCnpj }}</td>
            <td>{{ props.item.cliente.razaoSocialNome }}</td>
            <td class="text-right">{{ props.item.total_nota | formatarMoeda }}</td>
            <td class="text-center">
              <v-menu top right transition="slide-x-transition">
                <template v-slot:activator="{ on }">
                  <v-icon @click.stop="" v-on="on" v-if="props.item.status != constantes.STATUS_INUTILIZADA">more_vert</v-icon>
                </template>
                <v-list dense>
                  <v-list-item
                    :key="props.item._id + 20"
                    @click="editarNota(props.item)"
                    v-show="props.item.status !== constantes.STATUS_AUTORIZADA && props.item.status !== constantes.STATUS_CANCELADA && props.item.status !== constantes.STATUS_PROCESSAMENTO"
                  >
                    <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                    <v-list-item-title>Editar Nota</v-list-item-title>
                  </v-list-item>
                  <v-list-item :key="props.item._id + 10" @click="reprocessar({ id: props.item._id })" v-show="props.item.status === constantes.STATUS_CADASTRADA" :disabled="certificadoVencido">
                    <v-list-item-icon><v-icon>check_circle</v-icon></v-list-item-icon>
                    <v-list-item-title>Autorizar Nota</v-list-item-title>
                  </v-list-item>
                  <v-list-item :key="props.item._id + 5" @click="reprocessar({ id: props.item._id })" v-show="props.item.status === constantes.STATUS_ERRO" :disabled="certificadoVencido">
                    <v-list-item-icon><v-icon>replay</v-icon></v-list-item-icon>
                    <v-list-item-title>Reprocessar Nota</v-list-item-title>
                  </v-list-item>
                  <v-list-item :key="props.item._id" @click="reprocessar({ id: props.item._id })" v-show="props.item.status === constantes.STATUS_PROCESSAMENTO" :disabled="certificadoVencido">
                    <v-list-item-icon><v-icon>replay</v-icon></v-list-item-icon>
                    <v-list-item-title>Consultar Processamento</v-list-item-title>
                  </v-list-item>
                  <v-list-item :key="props.item._id + 15" @click="visualizarNota(props.item)" v-show="props.item.status !== constantes.STATUS_PROCESSAMENTO">
                    <v-list-item-icon><v-icon>mdi-eye</v-icon></v-list-item-icon>
                    <v-list-item-title>Visualizar Nota</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    :key="props.item._id + 30"
                    @click="inutilizarNota(props.item)"
                    v-show="props.item.status !== constantes.STATUS_AUTORIZADA && props.item.status !== constantes.STATUS_CANCELADA && props.item.status !== constantes.STATUS_PROCESSAMENTO"
                  >
                    <v-list-item-icon><v-icon>mdi-card-bulleted-off-outline</v-icon></v-list-item-icon>
                    <v-list-item-title>Inutilizar Nota</v-list-item-title>
                  </v-list-item>
                  <v-list-item :key="props.item._id + 40" @click="cancelarNota(props.item)" v-show="props.item.status === constantes.STATUS_AUTORIZADA">
                    <v-list-item-icon><v-icon>cancel</v-icon></v-list-item-icon>
                    <v-list-item-title>Cancelar Nota</v-list-item-title>
                  </v-list-item>
                  <v-list-item :key="props.item._id + 50" @click="abrirDialogCorrecao(props.item)" v-show="props.item.status === constantes.STATUS_AUTORIZADA">
                    <v-list-item-icon><v-icon>mail_outline</v-icon></v-list-item-icon>
                    <v-list-item-title>Carta de Correção</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <tr>
            <td :colspan="headers.length" class="px-0">
              <v-card flat>
                <v-card-title v-if="item.status === constantes.STATUS_AUTORIZADA">Informações Adicionais</v-card-title>
                <v-card-title v-else-if="item.status === constantes.STATUS_CANCELADA">Eventos</v-card-title>

                <v-card-text v-if="item.status === constantes.STATUS_AUTORIZADA">
                  <v-row dense>
                    <v-col cols="12" sm="6">
                      <v-text-field outlined label="Chave de Acesso" :value="item.chave | formatarChave" readonly hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field outlined label="Protocolo" :value="item.autorizacao.protocolo" readonly hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field outlined label="Data da Autorização" :value="item.autorizacao.data | formatarDataHora" readonly hide-details></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="item.eventos.length > 0">
                    <v-container fluid>
                      <v-data-iterator :items="item.eventos" :items-per-page="9" hide-default-footer>
                        <template v-slot:default="props">
                          <v-row dense>
                            <v-col v-for="evento in props.items" :key="evento._id" sm="4">
                              <v-card flat>
                                <v-list dense>
                                  <v-list-item>
                                    <v-list-item-icon>
                                      <v-icon v-if="evento.tipo == '110111'">cancel</v-icon>
                                      <v-icon v-else-if="evento.tipo == '110110'">mail_outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title>{{ definirNomeEvento(evento.tipo) }}</v-list-item-title>
                                      <v-list-item-subtitle>Protocolo: {{ evento.protocolo }}</v-list-item-subtitle>
                                      <v-list-item-subtitle>Data: {{ evento.data | formatarDataHora }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-card>
                            </v-col>
                          </v-row>
                        </template>
                      </v-data-iterator>
                    </v-container>
                  </v-row>
                </v-card-text>

                <v-card-text v-else-if="item.status === constantes.STATUS_CANCELADA">
                  <v-container fluid>
                    <v-data-iterator :items="item.eventos" :items-per-page="9" hide-default-footer>
                      <template v-slot:default="props">
                        <v-row dense>
                          <v-col v-for="evento in props.items" :key="evento._id" sm="4">
                            <v-card flat>
                              <v-list dense>
                                <v-list-item>
                                  <v-list-item-icon>
                                    <v-icon v-if="evento.tipo == '110111'">cancel</v-icon>
                                    <v-icon v-else-if="evento.tipo == '110110'">mail_outline</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>{{ definirNomeEvento(evento.tipo) }}</v-list-item-title>
                                    <v-list-item-subtitle>Protocolo: {{ evento.protocolo }}</v-list-item-subtitle>
                                    <v-list-item-subtitle>Data: {{ evento.data | formatarDataHora }}</v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-card>
                          </v-col>
                        </v-row>
                      </template>
                    </v-data-iterator>
                  </v-container>
                </v-card-text>

                <v-card-text v-if="item.status === constantes.STATUS_ERRO">
                  <v-row dense>
                    <v-col cols="12" sm="2">
                      <v-text-field outlined label="Código" v-model="item.retorno_sefaz.status" color="red" readonly hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="10">
                      <v-text-field outlined label="Descrição do Erro" v-model="item.retorno_sefaz.motivo" color="red" readonly hide-details></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-text v-if="item.status === constantes.STATUS_INUTILIZADA">
                  <v-row dense>
                    <v-col cols="12" sm="2">
                      <v-text-field outlined label="Protocolo" v-model="item.inutilizacao.protocolo" readonly hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="10">
                      <v-text-field outlined label="Resultado" v-model="item.inutilizacao.motivo" readonly hide-details></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>

    <dialog-copiar-nota ref="dialogCopiarNota"></dialog-copiar-nota>
    <dialog-inutilizacao ref="dialogoInutilizarIntervalo"></dialog-inutilizacao>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import DialogCartaCorrecao from './DialogCartaCorrecao';
import DialogInutilizacao from './DialogInutilizacao';
import DialogCopiarNota from './DialogCopiarNota';
import constantes from '@/util/constantes';

export default {
  components: {
    DialogCartaCorrecao,
    DialogInutilizacao,
    DialogCopiarNota,
  },

  data() {
    return {
      headers: [
        { text: 'Número', value: 'numero', sortable: false },
        { text: 'Série', value: 'serie', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Tipo', value: 'tipo', sortable: false },
        { text: 'Data', value: 'data_emissao', sortable: false },
        { text: 'CNPJ / CPF', value: 'cnpjCpf', sortable: false },
        { text: 'Nome do Cliente / Fornecedor', value: 'razaoSocialNome', sortable: false },
        { text: 'Valor', value: 'valor', align: 'right', sortable: false },
        { text: 'Ações', value: 'name', align: 'center', sortable: false },
      ],
      mostrarDialogCartaCorrecao: false,
      mostrarInutilizarIntervalo: false,
      notaSelecionada: {},
      constantes: constantes,
      busca: '',
      options: {
        itemsPerPage: 10,
        page: 1,
      },
    };
  },

  watch: {
    options: {
      handler() {
        this.carregarNotas();
      },
      deep: true,
    },
  },

  computed: {
    ...mapState('nota', {
      notas: 'notas',
      carregando: 'carregando',
      total: 'totalNotas',
    }),

    ...mapState({
      usuario: 'usuario',
      certificadoVencido: 'certificadoVencido',
      carregandoStatus: 'carregando',
      statusSefaz: 'statusSefaz',
    }),
  },

  methods: {
    ...mapActions('nota', {
      reprocessar: 'reprocessarNota',
    }),

    consultarStatusServicoNFe() {
      this.$store.dispatch('sefaz/consultarStatusServico', true, 'NFe');
    },

    editarNota(nota) {
      const modulo = this.$store.state.empresa?.modulo;
      if (modulo === 'notaFiscal') {
        this.$store.commit('nota/editar', nota);
        this.$router.push('/notas/editar');
      } else if (modulo === constantes.MODO_DEPOSITO) {
        this.$store.commit('notaDeposito/editar', nota);
        this.$router.push('/notas/transferencia');
      }
    },

    visualizarNota(nota) {
      this.$store.dispatch('nota/novoVisualizar', nota);
      // this.$store.commit('nota/visualizar', nota);
      // this.$router.push('/notas/danfe_novo');
    },

    novaNota() {
      const modulo = this.$store.state.empresa?.modulo;
      if (modulo === 'cupomFiscal') {
        this.$store.commit('nota/novo');
        this.$router.push('/notas/nova');
      }
      // else if (plano === constantes.MODO_DEPOSITO) {
      //   this.$store.commit('notaDeposito/nova');
      //   this.$router.push('/notas/transferencia');
      // }
    },

    carregarNotas() {
      this.$store.dispatch('nota/carregarNotas', this.options);
    },

    limparBusca() {
      this.carregarNotas();
    },

    filtrarNotas(event) {
      const newOptions = {
        ...this.options,
        query: this.busca,
      };

      this.$store.dispatch('nota/carregarNotas', newOptions);
      setTimeout(() => {
        event.target.select();
      }, 300);
    },

    inutilizarNota(nota) {
      if (nota.status !== constantes.STATUS_AUTORIZADA) {
        this.$root.$confirmacao.open('Inutilização', 'Tem certeza que deseja inutilizar a numeração?').then((confirmado) => {
          if (confirmado) {
            this.$store.dispatch('nota/inutilizarNota', nota);
          }
        });
      }
    },

    cancelarNota(nota) {
      if (nota.status === constantes.STATUS_AUTORIZADA) {
        this.$root.$confirmacao.open('Cancelamento', 'Tem certeza que deseja cancelar a nota?').then((confirmado) => {
          if (confirmado) {
            this.$store.dispatch('nota/cancelarNota', nota);
          }
        });
      }
    },

    enviarCartaCorrecao(correcao) {
      this.mostrarDialogCartaCorrecao = false;
      this.$store.dispatch('nota/enviarCartaCorrecao', correcao);
    },

    abrirDialogCorrecao(nota) {
      this.notaSelecionada = nota;
      this.mostrarDialogCartaCorrecao = true;
    },

    definirCor(status) {
      if (status) {
        if (status == constantes.STATUS_AUTORIZADA) {
          return 'green';
        } else if (status == constantes.STATUS_PROCESSAMENTO) {
          return 'orange';
        } else if (status == constantes.STATUS_DENEGADA) {
          return 'black';
        } else if (status == constantes.STATUS_INUTILIZADA) {
          return 'navy blue';
        } else if (status == constantes.STATUS_CANCELADA) {
          return 'purple';
        } else if (status == constantes.STATUS_ERRO) {
          return 'red';
        } else if (status == constantes.STATUS_CADASTRADA) {
          return 'secondary';
        }
      } else {
        return 'pink';
      }
    },

    definirLetra(status) {
      if (status) {
        return status.charAt(0);
      } else {
        return '';
      }
    },

    definirNomeEvento(codigo) {
      if (codigo == '110111') {
        return 'Cancelamento de NF-e';
      } else if (codigo == '110110') {
        return 'Carta de Correção';
      }
    },
  },
};
</script>
