<template>
  <v-dialog v-model="dialog" max-width="800px" @keydown.esc="fechar" persistent>
    <v-form v-model="valid" ref="formCopiarNota" lazy-validation>
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-toolbar-title class="white--text">Copiar Nota</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon style="cursor: pointer" @click="fechar()">close</v-icon>
        </v-toolbar>
        <v-card-text class="pa-4">
          <v-row>
            <!-- <v-col cols="12" sm="12">
              <v-subheader class="title font-weight-medium">Buscar Nota</v-subheader>
            </v-col> -->
            <v-col cols="12" sm="3">
              <v-text-field label="Buscar Nota" hint="Pressione Enter para buscar" v-model="nota.numero" :rules="obrigatorio" append-icon="search" @keyup.enter="consultarNumeroNota()" @click:append="abrirDialogoBuscarNota"></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="Série" v-model="nota.serie" :rules="obrigatorio" @keyup.enter="consultarNumeroNota()"></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="Data" :value="nota.data_emissao | formatarData" readonly :disabled="nota.data_emissao === ''"></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="Total" :value="nota.total_nota | formatarMoeda" readonly :disabled="nota.total_nota === ''"></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="CNPJ/CPF" :value="nota.cliente.cnpjCpf | formatarCnpj" readonly :disabled="nota.cliente.cnpjCpf === ''"></v-text-field>
            </v-col>
            <v-col cols="12" sm="9">
              <v-text-field label="Cliente/Fornecedor" :value="nota.cliente.razaoSocialNome" readonly :disabled="nota.cliente.razaoSocialNome === ''"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed class="mr-2" @click="fechar()" :disabled="loading">Cancelar</v-btn>
          <v-btn depressed color="primary" @click="copiar" :loading="loading" :disabled="!nota._id || nota._id === '' || !valid">Copiar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from '@/util/axios';

export default {
  data: () => ({
    dialog: false,
    valid: true,
    serie_padrao: '',
    nota: {
      numero: '',
      serie: '',
      data_emissao: '',
      total_nota: '',
      cliente: {
        cnpjCpf: '',
        razaoSocialNome: '',
      },
    },
    loading: false,
    obrigatorio: [(v) => !!v || 'Orbigatório'],
  }),

  methods: {
    abrir() {
      this.dialog = true;
    },

    fechar() {
      this.dialog = false;
      this.limpar();
      this.$refs.formCopiarNota.resetValidation();
    },

    abrirDialogoBuscarNota() {
      //
    },

    consultarNumeroNota() {
      this.loading = true;
      const numero = this.nota.numero;
      let serie = this.nota.serie;
      if (numero && numero.length > 0 && serie && serie.length > 0) {
        const empresa = this.$store.state.empresa;
        serie = serie.toString().padStart(3, '0');
        axios
          .get(`/${empresa._id}/notas/1/buscar?numero=${numero}&serie=${serie}`)
          .then((response) => {
            const resultado = response.data;
            if (resultado) {
              this.nota = resultado;
            } else {
              this.limpar();
              this.$store.commit('setMensagemErro', 'Nota não encontrada');
            }
            this.loading = false;
          })
          .catch((erro) => {
            this.$store.commit('setMensagemErro', erro.response.data.motivo);
            this.loading = false;
          });
      } else {
        this.$store.commit('setMensagemErro', 'Número/Série Inválido(s)');
        this.loading = false;
        this.limpar();
      }
    },

    async copiar() {
      if (this.$refs.formCopiarNota.validate()) {
        try {
          this.loading = true;
          const empresa = this.$store.state.empresa;
          const response = await axios.post(`/${empresa._id}/notas/copiar`, { id: this.nota._id });
          if (response.status === 200) {
            this.$store.commit('nota/adicionar', response.data);
            this.$store.commit('setMensagemSucesso', 'Nota Copiada com Sucesso');
            this.fechar();
          } else {
            this.$store.commit('setMensagemErro', 'Falha ao Copiar Nota');
          }
        } catch (error) {
          this.$store.commit('setMensagemErro', error);
        } finally {
          this.loading = false;
        }
      }
    },

    limpar() {
      this.nota = {
        numero: '',
        serie: this.serie_padrao,
        data_emissao: '',
        total_nota: '',
        cliente: {
          cnpjCpf: '',
          razaoSocialNome: '',
        },
      };
    },
  },

  async created() {
    try {
      this.loading = true;
      await this.$store.dispatch('nota/carregarEmpresa');
      const configTributaria = this.$store.state.nota.empresa.configuracao_tributaria;
      if (configTributaria) {
        this.nota.serie = configTributaria.serie_padrao;
        this.serie_padrao = configTributaria.serie_padrao;
      }
    } catch (error) {
      this.$store.commit('setMensagemErro', error);
    } finally {
      this.loading = false;
    }
  },
};
</script>
